import {useState} from 'react'
import {useTranslation} from 'next-i18next'
import {Controller, useForm} from 'react-hook-form'
import AppTextField, {Label} from 'src/components/forms/textField/AppTextField'
import AppTextArea from 'src/components/forms/textArea/AppTextArea'
import AppSelect from 'src/components/forms/Select'
import AppRoundedButton from 'src/components/elements/buttons/AppRoundedButton'
import InputError from 'src/components/elements/helpers/InputError'
import ExistingBookingOption from './ExistingBookingOption'
import ReservationNumber from './ReservationNumber'
import {publicContactUsApi} from 'src/services/api/contactUs'
import {ValidationError, fieldError} from 'src/hooks/form'
import {useCurrentLocale} from 'src/hooks/locale'
import {ContactUsData} from 'src/types/contactUs'
import {formResolver} from 'src/services/validation/contactUs'
import {LOCALE_EN} from 'src/constants/locale'
import {useGetRequestTypes} from './data'
import {useStyles} from './styles'
import PhoneInput from 'react-phone-input-2'

export default function ContactUsForm(props: {version?: 'page' | 'modal'}) {
  const {version = 'page'} = props
  const [loading, setLoading] = useState<boolean>(false)
  const [serverError, setServerError] = useState<ValidationError<any>>(null)
  //  const [attachments, setAttachments] = useState<File[]>([])
  const [sent, setSent] = useState<boolean>(false)

  const classes = useStyles()
  //const inputFileRef = useRef<HTMLInputElement | null>(null)
  const currentLocale = useCurrentLocale()
  const {t} = useTranslation(['common'])
  const requestTypes = useGetRequestTypes()
  // const attachmentsError = useAttachmentsError(attachments)

  const {
    control,
    register,
    watch,
    formState: {errors},
    handleSubmit,
    clearErrors,
    reset,
    setValue,
  } = useForm<ContactUsData>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: formResolver,
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      //    destination: '',
      //    start_date: '',
      //    end_date: '',
      request_type: 'i_am_a_guest',
      existing_booking_option: 'no',
      reservation_number: '', //change for booking_uid
      message: '',

      honeypot: '',
    },
  })

  const nameError = fieldError('name', {
    form: errors,
    response: serverError,
  })
  const emailError = fieldError('email', {form: errors, response: serverError})
  const requestTypeError = fieldError('request_type', {
    form: errors,
    response: serverError,
  })
  const existingBookingOptionError = fieldError('existing_booking_option', {
    form: errors,
    response: serverError,
  })
  const reservationNumberError = fieldError('reservation_number', {
    form: errors,
    response: serverError,
  })
  const messageError = fieldError('message', {
    form: errors,
    response: serverError,
  })

  const phoneNumberError = fieldError('phone', {
    form: errors,
    response: serverError,
  })

  const phoneNumber = watch('phone')
  const requestType = watch('request_type')
  const existingBookingOption = watch('existing_booking_option')
  const reservationNumber = watch('reservation_number')

  const getReservationNumber = () => {
    if (requestType !== 'i_am_a_guest' && requestType !== 'i_am_an_host') {
      return ''
    }

    if (existingBookingOption !== 'has_existing_booking') {
      return ''
    }

    return reservationNumber
  }

  const send = (data: ContactUsData) => {
    const formData = new FormData()
    for (const key in data) {
      formData.append(key, data[key as keyof ContactUsData])
    }
    formData.append('request_type_translated', t(data.request_type))
    formData.append('reservation_number', getReservationNumber())
    formData.append('locale', currentLocale ?? LOCALE_EN)
    /* if (attachments?.length) {
      Array.from(attachments).forEach((attachment) => {
        formData.append('attachments[]', attachment)
      })
    } */

    clearErrors()
    setServerError(null)
    setLoading(true)
    publicContactUsApi(formData)
      .then(() => {
        //setAttachments([])
        setSent(true)
        setTimeout(() => setSent(false), 7000) //reset in 7 seconds
        reset()
      })
      .catch((e) => setServerError(e.response.data))
      .finally(() => setLoading(false))
  }
  /*
  const onUploadFile = () => {
    if (!inputFileRef.current) {
      return
    }
    inputFileRef.current.click()
  }
  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return
    }
    const files = Array.from(attachments)
    const newFiles = Array.from(e.target.files)
    setAttachments([...files, ...newFiles])
  }

  //todo:Alex change index to id based so we dont delete the wrong one
  const removeAttachment = (index: number) => () => {
    if (attachments) {
      const files = Array.from(attachments)
      files.splice(index, 1)
      setAttachments(files)
    }
  }*/

  return (
    <div>
      <form className={classes.form} onSubmit={handleSubmit(send)}>
        <input
          style={{display: 'none'}}
          className="hidden"
          type="text"
          onChange={(e: any) => setValue('honeypot', e.target.value)}
          name="other_reason"
          value={watch('honeypot')}
        />
        <div
          className={`grid grid-cols-1 gap-6 ${
            version === 'modal' ? 'md:grid-cols-2' : ''
          }`}
        >
          <AppTextField
            inputProps={register('name', {
              required: 'Name is required',
            })}
            label={t('name')}
            placeholder={t('name_placeholder')}
            error={Boolean(nameError)}
            message={nameError ? t(nameError) : ''}
          />

          <AppTextField
            inputProps={register('email', {
              required: `${t('Email is required')}`,
            })}
            label={t('email')}
            placeholder={t('email_placeholder')}
            error={Boolean(emailError)}
            message={emailError ? t(emailError) : ''}
          />
        </div>
        <div
          className={`grid grid-cols-1 gap-6 ${
            version === 'modal' ? 'md:grid-cols-2' : ''
          }`}
        >
          <div>
            <Label name="phone" optional label={t('Phone Number')} />
            <PhoneInput
              country="ca"
              value={phoneNumber}
              onChange={(value: string) => setValue('phone', value)}
            />
            <InputError>{phoneNumberError}</InputError>
          </div>
          <Controller
            name="request_type"
            control={control}
            render={({field: {ref, ...others}}) => (
              <AppSelect
                withLabel
                label={t('type_of_request')}
                options={requestTypes}
                placeholder={t('type_of_request_placeholder')}
                {...others}
                inputRef={ref}
                error={Boolean(requestTypeError)}
                message={requestTypeError ? t(requestTypeError) : ''}
              />
            )}
          />
        </div>

        <ExistingBookingOption
          requestType={requestType}
          existingBookingOptionError={existingBookingOptionError}
          control={control}
        />
        <ReservationNumber
          register={register}
          requestType={requestType}
          existingBookingOption={existingBookingOption}
          reservationNumberError={reservationNumberError}
        />
        <Controller
          name="message"
          control={control}
          rules={{required: 'Message is required'}}
          render={({field: {ref, ...others}}) => (
            <AppTextArea label={t('contact_us_message')} {...others} />
          )}
        />
        <InputError>{messageError ? t(messageError) : ''}</InputError>
        {/*}  <input
          type="file"
          ref={inputFileRef}
          onChange={onFileChange}
          style={{visibility: 'hidden', width: 0, height: 0}}
          multiple
        />
        <AppButton
          fullWidth
          startIcon={<AppIcon name="upload" />}
          className="mt-2"
          variant="outlined"
          disabled={loading}
          onClick={onUploadFile}
        >
          {t('upload')}
        </AppButton>
        <InputError>{attachmentsError ? attachmentsError : ''}</InputError>
        <Attachments files={attachments} onRemove={removeAttachment} /> */}
        {sent ? (
          <div className="text-center w-full mt-2 p-2 rounded bg-green text-white font-medium">
            {t('sent')}
          </div>
        ) : (
          <AppRoundedButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading || sent}
            fullWidth
          >
            <span className="text-white">{t('send')}</span>
          </AppRoundedButton>
        )}
      </form>
    </div>
  )
}
